<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div v-if="category != null" class="page__title">{{ category.displayName }}</div>
                <div class="page__desc">Укажите категорию, с которой связать данный каталог METRO</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="category !== null" class="form-group" method="POST" action="" @submit.prevent="update()">

                <label for="category_id">Категория</label>
                <div class="control-group">
                    <select
                            v-model="category.market_category_id"
                            id="category_id"
                            name="category_id"
                            class="form-control"
                            required>

                        <template
                                v-for="cc in categoriesTree">

                            <optgroup
                                    v-bind:key="cc.id"
                                    :label="cc.title">

                                <option v-for="c in cc.categories"
                                        v-bind:key="c.id"
                                        :value="c.id"
                                        :selected="category.market_category_id === c.id">{{ c.title }}</option>
                            </optgroup>
                        </template>
                    </select>
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Назначить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'MetroCategoriesSettings',
        computed: {
            products() {
                return this.$store.state.metro.metroProducts
            },
            category() {
                return this.$store.state.metro.metroCategory
            },
            categoriesTree() {
                return this.$store.state.catalog.categoriesTree
            },
        },
        methods: {
            ...mapActions([
                'metroCategoriesProducts',
                'metroCategoriesSettings',
                'catalogCategoriesTree',
            ]),
            async getProducts() {
                await this.metroCategoriesProducts({
                    id: this.$route.params.id,
                })
            },
            async getCategoriesTree() {
                await this.catalogCategoriesTree()
            },
            async update() {
                if (this.category.market_category_id == null) {
                    return;
                }

                await this.metroCategoriesSettings({
                    id: this.$route.params.id,
                    category_id: this.category.market_category_id
                })
            }
        },
        created() {
            this.getProducts();
            this.getCategoriesTree();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>